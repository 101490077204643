html,
body {
  height: 100%;
  width: 100%;
  line-height: 1.5;
  --scrollbarBG: #fff;
  --thumbBG: #3f51b5;
  scrollbar-width: thin;
  scrollbar-color: #fff #3f51b5 !important;
}
html::-webkit-scrollbar {
  width: 11px;
  background-color: var(--scrollbarBG);
}
html::-webkit-scrollbar-track {
  background-color: var(--scrollbarBG);
}
html::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
body {
  font-family: 'Roboto', sans-serif;
}

body.fontLoaded {
  font-family: 'Roboto', sans-serif;
}
#app { 
  background-color: #fafafa;
  min-height: 100%;
  min-width: 100%;
}
p,
label {
  font-family: 'Roboto', sans-serif;
  line-height: 1.5em;
}
.MuiIconButton-root, .MuiIconButton-root:hover {
  font-size: unset !important;
}
.MuiButton-contained {
  color: #fff !important;
  background-color: transparent !important;
  box-shadow: none !important;
}